function scrollToo ($e, duration, y) {
    y = y || 0;
    duration = duration || 300;
    var _top = $e.offset().top + y;
    $('html,body').animate({"scrollTop" : _top}, duration);
    return false;
}

var totop = function() {
    totop.$but = $('.totop');
    totop.$window = $(window);
    totop.$scrollEl = $('#head');
    totop.duration = 500;

    totop.showBut();
    totop.windowEvents();
    totop.scrollToTop();
}

totop.scrollToTop = function() {
    totop.$but.click(function() {
        $('html,body').animate({
            scrollTop: 0
        }, totop.duration)
    });
}

totop.showBut = function() {
    var point = totop.$scrollEl.offset().top + totop.$scrollEl.height() + 30;
    totop.$window.scrollTop() >= point && window.innerWidth > 768 ? totop.$but.addClass('vis') : totop.$but.removeClass('vis');
}

totop.windowEvents = function() {
    totop.$window.on('scroll', function() {
        setTimeout(function() {
            totop.showBut()
        }, 50)
    }), totop.$window.on('resize', function() {
        setTimeout(function() {
            totop.showBut()
        }, 50)
    })
}

function sliderExec () {
    if ($('#slider').length) {
        var options = {
            duration: 7000,
            // prevNext: true,
            directory: $('meta[name="slider-url"]').attr('content'),
            directoryLoader: $('meta[name="loader-url"]').attr('content')
        };

        sliderExec.slider = new Slider(images, {
            container: $('.slider .slider__box'),
            navigation: $('.slider .slider__navigation'),
        }, options);
    }
}

function placeholder () {
    var fields = $('.forma__field__itext, .forma__field textarea');
    var selectors = $('.forma__field__select');

    fields.each(function() {
        var item = $(this);
        if (item.val() != '' && item.val() != item.parent().find('label').text()) item.parent().find('label').hide();
    });

    fields.on('keyup', function () {
        var item = $(this);
        if (item.val() != '' && item.val() != item.parent().find('label').text()) item.parent().find('label').hide();
        else item.parent().find('label').show();
    }).on('focusin', function () {
        var item = $(this);
        item.parent().addClass('forma__inp_light');
        if (item.data('validate') == 'date') item.parent().find('label').hide();
    }).on('focusout', function () {
        var item = $(this);
        item.parent().removeClass('forma__inp_light');
    });

    selectors.on('change', function() {
        if ($(this).val() !== '') $(this).prev().hide();
    });

    // fields.focusin(function () {
    //     $(this).parent().addClass('forma__inp_focus forma__inp_light');
    // }).focusout(function () {
    //     $(this).parent().removeClass('forma__inp_light');
    //     if ($(this).val() == '') $(this).parent().removeClass('forma__inp_focus');
    // });
}

/*********************************
********* + Menu mobile **********
*********************************/

function menuToggle () {
    var menu = $('.menuContainer');
    var toggleBut = $('.menuToggle');
    var plusBut = $('#mm .mm__parent .plus');

    toggleBut.on('click', function () {
        if (!menu.hasClass('vis')) {
            menu.addClass('vis');
            toggleBut.addClass('active');
        } else {
            menu.removeClass('vis');
            toggleBut.removeClass('active');
        }
    });

    plusBut.on('click', function (event) {
        event.preventDefault();
        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active');
            $(this).parent().next('.subs').slideUp(300, 'linear');
        } else if (!$(this).next().is('.subs:animated')) {
            $(this).parent().addClass('active');
            $(this).parent().next('.subs').slideDown(300, 'linear');
        }
    });

    $(window).on('resize', function() {
        setTimeout(function() {
            if (window.innerWidth >= 769) {
                menu.find('.subs').each(function () { 
                    $(this).show();
                    if ($(this).hasClass('subs_columns')) $(this).css('display', 'flex');
                });
            } else {
                plusBut.each(function () {
                    if (!$(this).hasClass('active')) $(this).next('.subs').hide();
                });
                menu.find('.subs_columns').each(function() {
                    $(this).attr('style', '');
                });
            }
        }, 50);
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (window.innerWidth < 769 && !$target.is(menu) && !menu.find($target).length && !$target.is(toggleBut) && !toggleBut.find($target).length && toggleBut.hasClass('active')) toggleBut.trigger('click');
    });
}

function carousel () {
	$('.owl-carousel-new').each(function() {
        $(this).owlCarousel({
            loop:true,
            margin:60,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true
                },
                480:{
                    items:2,
                    nav:true
                },
                768:{
                    items:3,
                    nav:true
                }
            }
        });
    });
    
    var owl = $('.slider__box');
    owl.owlCarousel({
        items:1,
        loop:true,
        margin:0,
        autoplay:true,
        autoplayTimeout:8000,
        autoplayHoverPause:true
    });
    // $('.play').on('click',function(){
    //     owl.trigger('play.owl.autoplay',[1000])
    // })
    // $('.stop').on('click',function(){
    //     owl.trigger('stop.owl.autoplay')
    // });
}

function catalogListImage () {
    var bullet = $('.catalog__item__bullets span');
    var items = $('.catalog__item__inside');

    bullet.on('click mouseenter', function() {
        if (!$(this).hasClass('active')) {
            $(this).parent().find('.active').removeClass('active');
            var src = $(this).data('src');
            $(this).addClass('active').parents('.catalog__item__inside').find('.catalog__item__image img').attr('src', src);
        }
    });

    items.on('mouseleave', function() {
        var bullets = $(this).find('.catalog__item__bullets');
        if (bullets.find('.active').index()) bullets.find('span').eq(0).trigger('click');
    });
}

function docsCarousel () {
    $('.owl-carousel-docs').each(function() {
        $(this).owlCarousel({
            loop:false,
            margin:60,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true
                },
                480:{
                    items:2,
                    nav:true
                },
                640:{
                    items:3,
                    nav:true
                },
                769:{
                    items:4,
                    nav:true
                }
            }
        });
    });
}

/********************************
******** END Menu mobile ********
********************************/

function sendMsg () {
    var popup = $('.fixPopupMsg');
    var msg = $('.forma__sendMsg');
    var closeBut = $('.fixPopupMsg__close');
    
    var timeout;
    if (msg.find('div').length) {
        var classMsg = msg.find('div').attr('class');
        var textMsg = msg.find('div').text();
        popup.prepend('<div class="'+classMsg+'">'+textMsg+'</div>');
        popup.addClass('vis');
        timeout = setTimeout(function() {
            popup.animate({opacity : 'hide'}, 300, function() {
                $(this).removeClass('vis');
            });
        }, 5000);
        //popup.prepend('div class="'+classMsg+'"></div>')
    }

    closeBut.on('click', function() {
        clearTimeout(timeout);
        popup.animate({opacity : 'hide'}, 300, function() {
            $(this).removeClass('vis');
        })
    });
}

var Tags = function (buttons, items, classVis) {
    this.buttons = buttons;
    this.items = items;
    this.indexItem = 0;
    this.classVis = classVis || 0;

    this.eventButtons();
    if (this.buttons.find('.sel').length) this.sort();
}

Tags.prototype.statusButtons = function () {
    this.buttons.find('.sel').removeClass('sel');
    this.buttons.find('div').eq(this.indexItem).addClass('sel');
}

Tags.prototype.sort = function () {
    var e = this.buttons.find('.sel').data('type');
    var classVis = this.classVis ? this.classVis : 0;
    this.items.each(function() {
        if (classVis) $(this).data('type') === e || 0 === e ? $(this).addClass(classVis) : $(this).removeClass(classVis);
        else $(this).data('type') === e || 0 === e ? $(this).show() : $(this).hide();
        //$(this).data('type') == e || 0 == e ? $(this).show() : $(this).hide();
    });
}

Tags.prototype.eventButtons = function () {
    this.buttons.find('div').click(function (event) {
        if (!$(event.target).hasClass('sel')) {
            this.indexItem = $(event.target).index();
            this.statusButtons();
            this.sort();
        }
    }.bind(this));
}

function tagsCarouselExec() {
    new Tags($('.galleries .tags'), $('.carouselBox .carousel'), 'visCarousel');
}

function tagsAlbumsExec() {
    new Tags($('.tags'), $('.gallery_albums .gallery__item'));
}

function popupGal() {
    var options = {
        delegate: 'a',
        type: 'image',
        tLoading: 'Загружено #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>', // markup of counter
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
            titleSrc: function(item) {
                return item.el.attr('title') + '<small></small>';
            }
        }
    };
    $('.popup-gallery-0').magnificPopup(options);
    $('.popup-gallery-1').magnificPopup(options);
    $('.popup-gallery-2').magnificPopup(options);
    $('.popup-gallery-doc').magnificPopup(options);
}

function catalogItemGallery () {
    // var gal = $('.catalogItem .gallery_catalog');
    // var image = $('.catalogItem__image');

    $('body').on('click', '.gallery_catalog a', function(event) {
        if (!$(this).hasClass('sel')) {
            $(this).parent().find('.sel').removeClass('sel');
            $(this).addClass('sel');
            $(this).parents('.catalogItem').find('.catalogItem__image').attr('src', $(this).data('large')).parent().attr('href', $(this).attr('href'));
            //image.attr('src', $(this).attr('href'));
        }
        event.preventDefault();
    });

    $('.catalogItem__images').magnificPopup({
		delegate: 'a',
        type: 'image',
        tLoading: 'Загружено #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>', // markup of counter
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
            titleSrc: function(item) {
                return item.el.attr('title') + '<small></small>';
            }
        }
	});
}

var filters = function () {
    filters.forma = $('.filters form');
    filters.body = $('#main');
	filters.resetBut = $('.filters .filters__reset span');
    filters.showMore = $('.filters__item__more span');
    filters.hideMore = $('.filters__item__hide span');
    filters.openFilters = $('.filters .filters__caption div');
    filters.openCateg = $('.categories .categories__caption');
    filters.sortingSelect = $('.sorting select');
    filters.sortingInp = $('#sort');

    filters.requestNow = 0;
    filters.shadow = 0;
    filters.timeout = 0;
    filters.shadowTmpl = '<div class="shadow-request"></div>';

    filters.keypressSlider = 0;
    filters.rangeStart =  Number($('meta[name="catalog:cost-min"]').attr('content'));
    filters.rangeEnd =  Number($('meta[name="catalog:cost-max"]').attr('content'));
    filters.rangeMin =  Number($('meta[name="catalog:cost-min"]').attr('content'));
    filters.rangeMax =  Number($('meta[name="catalog:cost-max"]').attr('content'));
	filters.sliderChange = 0;
	
	filters.inputMin = document.getElementById('costMin');
    filters.inputMax = document.getElementById('costMax');

    filters.forma.find('input:checkbox, input:radio').change(function () {
        if (!filters.requestNow) filters.submitForm();
    });

    filters.resetBut.click(function () {
		window.location = window.location.href.split('?')[0];
    });

    if ($('#costMin').length) filters.range = 1;

    filters.exec();
    //filters.sorting();
    filters.showMoreInfo();
}

filters.resetSliderRange = function () {
    filters.rangeStart = filters.rangeMin;
    filters.rangeEnd = filters.rangeMax;
    filters.keypressSlider.noUiSlider.reset();
}

filters.resetForm = function (urlParamsObject) {
    var counter = 0;
    for (var key in urlParamsObject) {
        counter++;
    }

    if (counter > 2 || filters.sliderChange) filters.resetBut.show();
}

filters.shadowShow = function () {
    if (filters.shadow) filters.shadow.css('visibility', 'visible');
    else {
        filters.body.prepend(filters.shadowTmpl);
        filters.shadow = $('.shadow-request');
        filters.shadow.css('visibility', 'visible');
    }
}

filters.submitForm = function () {
    filters.requestNow = 1;
    setTimeout(function () {
        filters.requestNow = 0;
        filters.forma.submit();
    }, 1000);
}

filters.checkboxEvents = function (urlParamsObject) {
    $.each(urlParamsObject, function(name, value) {
        if( typeof value !== 'object' ) {
            filters.forma.find('[name = "'+decodeURIComponent(name)+'"][value = "'+decodeURIComponent(value)+'"]').prop('checked', true);
        } else {
            $.each(filters.forma.find('[name = "'+decodeURIComponent(name)+'"]'), function() {
                if (value.indexOf($(this).val()) !== -1) $(this).prop('checked', true);
            });
        }
    });
}

filters.setSliderParams = function (urlParamsObject) {
    var start = urlParamsObject[filters.inputMin.name];
    var end = urlParamsObject[filters.inputMax.name];

    start = start.replace(/\+/g, '');
    end = end.replace(/\+/g, '');

    if (start != filters.rangeStart || end != filters.rangeEnd) filters.sliderChange = 1;

    filters.keypressSlider.noUiSlider.set([start, end]);
}

filters.sendSliderRange = function () {
    clearTimeout(filters.timeout);

    filters.timeout = setTimeout(function () {
        if (!filters.requestNow) filters.submitForm();
    }, 1000);
}

filters.getAllUrlParams = function () {
    var uri = decodeURI(location.search.substring(1));
    var vars = uri.split('&');
    var result = {};

    if (uri) {
        for (var i=0; i < vars.length; i++) {
            var r = vars[i].split('=');
            if(r[0].search("\\[\\]") !== -1) {
                typeof result[r[0]] === 'undefined' ? result[r[0]] = [r[1]] : result[r[0]].push(r[1]);
            } else {
                result[r[0]] = r[1];
            }
        }
    }

    return result;
}

filters.exec = function () {

    if (filters.range) filters.sliderRange(filters.rangeStart, filters.rangeEnd, filters.rangeMin, filters.rangeMax);

    var urlParamsObject = filters.getAllUrlParams();
    
    if (!$.isEmptyObject(urlParamsObject)) {
        filters.checkboxEvents(urlParamsObject);
        if (filters.range) filters.setSliderParams(urlParamsObject);
        filters.resetForm(urlParamsObject);
    }
}

filters.showMoreInfo = function () {

	filters.showMore.on('click', function() {
        $(this).parents('.filters__item').addClass('vis');
    });
    
    filters.hideMore.on('click', function() {
        $(this).parents('.filters__item').removeClass('vis');
    });

    filters.forma.find('.filters__item__overflow').each(function() {
        if ($(this).find('input:checked').length) $(this).parent().find('.filters__item__more span').trigger('click');
    });

    filters.openFilters.on('click', function() {
        filters.forma.hasClass('vis') ? filters.forma.slideDown(300).removeClass('vis') : filters.forma.slideUp(300).addClass('vis');
	});

    filters.openCateg.on('click', function() {
        $(this).next().css('display') == 'none' ? $(this).next().slideDown(300).addClass('vis') : $(this).next().slideUp(300).removeClass('vis');
    });

    $(window).on('resize', function() {
        setTimeout(function() {
            if (window.innerWidth >= 769) {
                if (!filters.forma.hasClass('vis')) filters.forma.show();
                if (!filters.openFilters.next().hasClass('vis')) filters.openFilters.next().show();
            }
        }, 50);
    });
}

filters.sorting = function () {
    var sortingValue;

    sortingValue = filters.sortingInp.val();
    filters.sortingSelect.find('[value = '+sortingValue+']').attr('selected', true);

    filters.sortingSelect.change(function() {
        sortingValue = $(this).val();
        filters.sortingInp.val(sortingValue);
        if (!filters.requestNow) filters.submitForm();
    })
}

filters.sliderRange = function (start, end, min, max) {//my parametres
    filters.keypressSlider = document.getElementById('costSlider');
    var inputs = [filters.inputMin, filters.inputMax];
    var stepSlider = Number($('meta[name="catalog:cost-step"]').attr('content'));
    var timeout;

    noUiSlider.create(filters.keypressSlider, {
    	start: [start, end],
    	connect: true,
        step: stepSlider,
    	range: {
    		'min': [min],
    		'max': max
    	},
        format: wNumb({
    		decimals: 0,
	        thousand: ' '
    	})
    });

    filters.keypressSlider.noUiSlider.on('update', function( values, handle ) {
    	inputs[handle].value = values[handle];
    });

    filters.keypressSlider.noUiSlider.on('change', function () {
        filters.sendSliderRange(); //my function
    });

    function setSliderHandle(i, value) {
    	var r = [null,null];
    	r[i] = value;
    	filters.keypressSlider.noUiSlider.set(r);
    }

    // Listen to keydown events on the input field.
    inputs.forEach(function(input, handle) {

    	input.addEventListener('change', function(){
            setSliderHandle(handle, this.value);
            //window.location = window.location.href.split('?')[0];
            filters.sendSliderRange(); //my function
    	});
    });
}

/********************************
********** Form Valid ***********
********************************/

var CheckForms = function (form, button, options) {
    this.form = form;
    this.button = form.find(button);

    this.options = $.extend({}, CheckForms.Defaults, options);

    if (this.options.radio) this.radioChange();

    this.checkEmpty();
    this.submitForm();
    this.removeError();
}

CheckForms.Defaults = {
    errorClass: 'errorField',
    emptyClass: 'emptyField',
    radio: 0,
}

CheckForms.prototype.checkEmpty = function () {
    this.form.find('.requiredField').each(function () {
        if ($(this).val() == '' || $(this).val() == $(this).parent().find('label').text() || ($(this).is('[type = checkbox') && !$(this).is('input:checked'))) $(this).addClass('emptyField');
    });

    this.checkAllElements();
}

CheckForms.prototype.checkCorrect = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    this.form.find('.requiredField').each(function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        /**
         * @TODO:
         * 1. Get field type
         * 2. Check field by correct method (checkInput, checkRadio, checkCheckbox, checkEmail, checkDate)
         * 
         * checkDate by data-validate="date" + data-validatePattern="yyyy.mm.dd". Example: yyyy.mm.dd || dd.mm.yyyy
         **/ 
        if (!itemValue || !mailChecked || itemValue == item.parent().find('label').text() || (item.is('[type = checkbox') && !item.is('input:checked'))) {
            item.addClass('errorField');
        }
        //if (item.is('[type = checkbox') && item.is('input:checked')) item.addClass('errorField');
    });

    this.checkAllElements();
}

CheckForms.prototype.removeError = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    //return patternEmail.test(value);

    this.form.on('keyup blur change', '.requiredField', function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        if (itemValue && itemValue != item.parent().find('label').text() && mailChecked && dateChecked) {
            item.removeClass('errorField emptyField');
        } else {
            item.addClass('emptyField');
        }

        if (item.is('[type = checkbox')) {
            item.is('input:checked') ? item.removeClass('errorField emptyField') : item.addClass('errorField');
        }

        if (item.is('select')) item.val() != '' ? item.removeClass('errorField emptyField') : item.addClass('errorField');

        var form = item.parents('form');
        var submitButton = form.find('.button_submit');
        if (!form.find('.emptyField').length && !form.find('.errorField').length) {
            submitButton.removeClass('button_disabled');
        } else {
            submitButton.addClass('button_disabled');
        }
    });
}

CheckForms.prototype.checkEmail = function (value) {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    return patternEmail.test(value);
}

CheckForms.prototype.checkAllElements = function () {
    if (/*this.form.data('agreement') && */!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
        this.button.removeClass('button_disabled');
    } else {
        this.button.addClass('button_disabled');
    }
}

CheckForms.prototype.submitForm = function () {
    this.button.click(function (event) {
        //this.checkEmpty();
        this.checkCorrect();
        event.preventDefault();
        if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
            this.form.submit();
        }
    }.bind(this));
}

function execContactsForm() {
    new CheckForms($('#requestForm'), '.button_submit');
}

let enrollCourse = function () {
    enrollCourse.forma = $('#requestForm');
    enrollCourse.features = enrollCourse.forma.find('ul');
    enrollCourse.price = enrollCourse.forma.find('.flexColumns__item__price');
    enrollCourse.selectDate = enrollCourse.forma.find('#dateCourse');
    enrollCourse.currency = $('meta[name="currency-name"]').attr('content');
    enrollCourse.subject = $('#subjectField');

    enrollCourse.execPopup();
    enrollCourse.execCheckForm();
}

enrollCourse.execCheckForm = function () {
    // let forma = $('#requestForm');
    let but = enrollCourse.forma.find('.button_submit');
    new CheckForms(enrollCourse.forma, but);
}

enrollCourse.fillPopupForm = function(id) {
    let item = $('#'+id);

    enrollCourse.features.empty();
    item.find('ul li').each(function() {
        enrollCourse.features.append($(this).clone());
    });

    enrollCourse.selectDate.html('<option value="" style="display: none;"></option>');
    item.find('.radioInp input').each(function() {
        let option = $('<option value="'+$(this).val()+'">'+$(this).next().text()+'</option>');
        enrollCourse.selectDate.append(option);
        if ($(this).is('input:checked')) {
            option.prop('selected', true);
            enrollCourse.selectDate.trigger('change');
        }
    });

    enrollCourse.price.text(numberFormat(item.find('.popupRequestForm').data('price'), {decimals: 0, thousands_sep: ' '}) + ' ' + enrollCourse.currency);

    enrollCourse.subject.val(item.find('.popupRequestForm').data('title'));
}

enrollCourse.execPopup = function () {
    $('.popupRequestForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			},
            elementParse: function(item) {
                // Function will fire for each target element
                // "item.el" is a target DOM element (if present)
                // "item.src" is a source that you may modify
            
                //console.log('Parsing content. Item object that is being parsed:', item);
                console.log($(item.el).data('id'))
                enrollCourse.fillPopupForm($(item.el).data('id'));
            },
		}
    });
}

/********************************
******** END Form Valid *********
********************************/

// function initMap() {
//     var mapCenter = $('meta[name="map-center"]').attr('content');

//     ymaps.ready(function () {
//         var myMap = new ymaps.Map('map', {
//             center: mapCenter.split(','),
//             zoom: 12
//         }, {
//             searchControlProvider: 'yandex#search'
//         });

//         myMap.behaviors.disable(['scrollZoom', 'rightMouseButtonMagnifier', 'multiTouch']);

//     	for (var key in places) {

//     		placesExec = new ymaps.Placemark(
//     		    places[key],
//     		    {
//     		        balloonContentHeader: '<div class="mcapt">' + places[key][2] + '</div>',
//     		        balloonContentBody: '<div class="mimage">' + places[key][3] + '</div>',
//     		        balloonContentFooter: '<div class="mbm">' + places[key][4] + '</div>'

//     		    },
//     		    {
//                     preset: 'islands#dotIcon',
//                     iconColor: '#943F7A'
//     		    }
//     		);
//     		myMap.geoObjects.add(placesExec);
//     	}
//     });
// }

var popupMap = function () {
    popupMap.$but = $('.openPopupMap');

    popupMap.openPopup();

    // popupMap.$but.click(function() {
    //     popupMap.openPopup();
    // });
}

popupMap.initMap = function () {
    var mapCenter = $('meta[name="map-center"]').attr('content');

    popupMap.$but.click(function () {
        if (!popupMap.myMap) {
            popupMap.myMap = new ymaps.Map('map', {
                center: mapCenter.split(','),
                zoom: 16,
                controls: ['fullscreenControl', 'zoomControl', 'geolocationControl'],
            }, {
                searchControlProvider: 'yandex#search'
            });
        }

        popupMap.myMap.behaviors.disable(['scrollZoom', 'rightMouseButtonMagnifier', 'multiTouch']);

    	for (var key in places) {

    		placesExec = new ymaps.Placemark(
    		    places[key],
    		    {
    		        balloonContentHeader: '<div class="mcapt">' + places[key][2] + '</div>',
    		        balloonContentBody: '<div class="mimage">' + places[key][3] + '</div>',
    		        balloonContentFooter: '<div class="mbm">' + places[key][4] + '</div>'

    		    },
    		    {
                    preset: 'islands#dotIcon',
                    iconColor: '#d5b651'
    		    }
    		);
    		popupMap.myMap.geoObjects.add(placesExec);
    	}
    })
}

popupMap.openPopup = function () {
    ymaps.ready(popupMap.initMap);

    popupMap.$but.magnificPopup({
        disableOn: 290,
		type: 'inline',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});
	$(document).on('click touchstart', '.mfp-close', function (e) {
		e.preventDefault();
		$.magnificPopup.close();
    });
}

function execShop () {
    if ($('meta[name="cart-url"]').length) shop();
}


x.exe['option-default'] = ['menuToggle()', 'placeholder()', 'totop()', 'execShop()'];
x.exe['option-index'] = [/*'sliderExec()', */'tagsCarouselExec()', 'popupGal()', 'carousel()'];
x.exe['option-content'] = ['popupGal()'];
x.exe['option-contacts'] = ['popupMap()', 'execContactsForm()', 'sendMsg()'];
x.exe['option-gallery-albums'] = ['tagsAlbumsExec()'];
x.exe['option-catalog'] = ['catalogListImage()', 'filters()'];
x.exe['option-catalog-item'] = ['catalogItemGallery()'];
x.exe['option-docs'] = ['popupGal()', 'docsCarousel()'];
x.exe['option-cart'] = ['popupMap()'];
x.exe['option-course'] = ['enrollCourse()'];